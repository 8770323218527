import React, { useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/module/Hero";

import banner from "../assets/image/inner-page/png/modules/finance-module-banner.png";
import Functionalities from "../sections/module/Functionalities";
import {
  BenefitData,
  FeatureData,
  FunctionalitiesData,
} from "../const/static-data";
import Features from "../sections/module/Features";
import Benefits from "../sections/module/Benefits";
import SectionTabs from "../sections/module/SectionTabs";
import ModuleImage from "../sections/module/ModuleImage";

const FinanceModule = () => {
  useEffect(() => {
    const currentPath = window.location.pathname;
    const withoutSlashesPath = currentPath.replace(/^\/+|\/+$/g, "").trim();
    const newPath = `/${withoutSlashesPath}/index.html`;
    window.history.replaceState({}, "", newPath);
  }, []);
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button className="btn btn btn-blue-3 header-btn-2 font-size-3 rounded-5">
                Start 14 Days Free Trial
              </button>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero title={"Finance"} banner={banner} />

        <SectionTabs />

        <section className="mt-15">
          <div className="container">
            <h2 className="font-size-8">About</h2>
            <p id="section2" className="gray-text  font-size-6">
              <span className="title">UNICLOUD360</span> Finance Portal allows
              the finance team to confidently manage end-to-end school finance
              using a cloud-driven management portal to lead towards saving
              money. Use the multitude of features of this portal to make the
              best out of your educational institution’s budget in a
              transparent, accurate and cost-efficient manner possible. It is an
              easy-to-use module to freely manage all financial operations,
              receipts, reconciliations, payments, tuition fee transactions, and
              more under one secured screen. With access to real-time cash flow
              data and detailed financial reports, the user can utilize the
              features to analyze operational performance using automated
              information generators and monitor cash flow in real time.
            </p>
          </div>
        </section>
        <Functionalities data={FunctionalitiesData.Finance} />
        <Features data={FeatureData.Finance} />
        <ModuleImage />
        <Benefits data={BenefitData.Finance} />
        {/*<Team />*/}
        {/*<Stats />*/}
      </PageWrapper>
    </>
  );
};
export default FinanceModule;
